import { mapActions, mapState } from 'vuex';

export default {
	name: 'AgreementSearchResults',
	props: {
		value: {
			type: [Number, String],
			required: false,
		},
		setResult: {
			type: Function,
			required: true,
		},
	},
	data: () => ({
		current_agreement: null,
	}),
	computed: {
		...mapState('agreement', ['agreements', 'agreementsPagination']),

		currentPage: {
			get() {
				return this.value || null;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
	created() {},
	methods: {
		...mapActions('agreement', [
			'searchAgreement',
			'getAgreement',
			'cleanAgreement',
			'cleanAgreements',
			'cleanTemplate',
		]),

		selectResult(result) {
			if (result?.id) {
				this.getAgreement(result?.id);
				this.current_agreement = result;
				this.setResult({ ...result, preview: 'AGREEMENT' });
			}
		},

		isSelected(result) {
			return result?.id == this.current_agreement?.id ? 'selected' : '';
		},

		search() {
			this.$emit('changePagination');
		},
	},
	beforeDestroy() {
		this.cleanAgreement();
		this.cleanAgreements();
		this.cleanTemplate();
	},
	components: {},
};
