import SearchForm from '@/components/contract/search/SearchForm/SearchForm.vue';

export default {
	name: 'SearchContent',
	props: {},
	data: () => ({
		searchForm: {
			more: false,
		},
		result_selected: null,
		findComponent: false,
		componentName: '',
		filters: {},
	}),
	computed: {
		breadcrumbs: function () {
			return [
				{
					text: 'Inicio',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'Dashboard',
					},
				},
				{
					text: 'Legal',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'ContractHome',
					},
				},
				{
					text: 'Búsqueda de Contratos / Cliente / Proveedor',
					disabled: true,
					href: '/',
				},
			];
		},
	},
	watch: {
		result_selected: {
			handler() {
				this.setComponentName();
			},
			deep: true,
		},
	},
	created() {},
	methods: {
		setComponentName() {
			this.findComponent = false;
			let foundComponent = null;
			const contractPages = this.result_selected?.contractPages;

			// const contractVersionPages =  this.result_selected?.contractVersions[0]?.contractVersionPages;
			const contractVersionPages = this.result_selected?.contractVersions && this.result_selected?.contractVersions.length >0 ? this.result_selected?.contractVersions : null;
			
			// const componentList = [
			// 	{ componentName: 'contract-preview', type: 'contract' },
			// 	{ componentName: 'pre-contract-preview', type: 'pre_contract' },
			// ];
			// const foundComponent = componentList.find(
			// 	(element) => element.type === this.result_selected?.type
			// );

			if (this.result_selected?.preview === 'PROFILE') {
				foundComponent = { componentName: 'profile-preview', type: 'profile' };
			} else if (this.result_selected?.preview === 'AGREEMENT') {
				foundComponent = {
					componentName: 'agreement-preview',
					type: 'agreement',
				};
			} else if (this.result_selected?.preview === 'DOCUMENT-KEYWORD') {
				foundComponent = {
					componentName: 'document-keyword-preview',
					type: 'keyword',
				};
			} else if (Array.isArray(contractPages) && contractPages?.length) {
			// } else if (Array.isArray(contractPages) && contractPages?.length) {
				
				foundComponent = { componentName: 'keyword-preview', type: 'keyword' };
			}else if(!this.result_selected?.typeDocumentSearch && Array.isArray(contractVersionPages) && contractVersionPages?.length){
				
				foundComponent = { componentName: 'keyword-preview', type: 'keyword' };
			}
			else {
			
				foundComponent = { componentName: 'contract-preview', type: 'contract' };
			}
			if (foundComponent) {
				this.componentName = foundComponent.componentName;
				this.findComponent = true;
			}
		},
		setFilters(v) {
			this.filters = v;
		},
	},
	destroyed() {},
	components: {
		SearchForm,
		ContractPreview: () =>
			import(
				'@/components/contract/search/elements/ContractPreview/ContractPreview.vue'
			),
		KeywordPreview: () =>
			import(
				'@/components/contract/search/elements/KeywordPreview/KeywordPreview.vue'
			),
		ProfilePreview: () =>
			import(
				'@/components/contract/search/elements/ProfilePreview/ProfilePreview.vue'
			),
		DocumentKeywordPreview: () =>
			import(
				'@/components/contract/search/elements/DocumentKeywordPreview/DocumentKeywordPreview.vue'
			),
		AgreementPreview: () =>
			import(
				'@/components/contract/search/elements/AgreementPreview/AgreementPreview.vue'
			),
	},
};
