<template>
	<search-content />
</template>
<script>
import SearchContent from '@/components/contract/search/SearchContent/SearchContent.vue';

export default {
	name: 'ContractSearchView',
	components: { SearchContent },
};
</script>
