<template>
	<div>
		<v-chip v-for="(tag, index) in tags || []" :key="index" small :color="tag.color" class="ma-1">
			{{ tag.title }}
		</v-chip>
	</div>
</template>
<script>
export default {
	name: 'TagList',
	props: {
		tags: {
			type: Array,
			required: false,
		},
	},
	computed: {
		// tags: {
		// 	get() {
		// 		return this.value || null;
		// 	},
		// 	set(value) {
		// 		this.$emit('input', value);
		// 	},
		// },
	},
	methods: {},
};
</script>
