import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import { $EventBus } from '@/main';
import CDatePicker from '@/components/global/CDatePicker.vue';
import TagList from '@/components/contract/search/elements/TagList.vue';
import SearchDocument from '@/components/contract/search/elements/SearchDocument/SearchDocument.vue';
import AgreementSearchResults from '@/components/contract/search/elements/AgreementSearchResults/AgreementSearchResults.vue';

export default {
	name: 'SearchForm',
	props: {
		value: {
			type: Object,
			required: false,
		},
	},
	data: () => ({
		moreFilters: false,
		results: [],
		inputSearch: null,
		filters: {
			q: null,
			document: null,
			status: null,
			date_from: null,
			date_to: null,
			document_type: null,
			page: 1,
			pagination: true,
			per_page: process.env.VUE_APP_PAGINATION_PER_PAGE || 10,
		},
		timeout: null,
		isLoading: false,
		documentTypes: [
			{ label: 'Carta', value: 'LETTER' },
			{ label: 'Adenda', value: 'ADDENDUM' },
			{ label: 'Acta', value: 'REPORT' },
		],
		indexTab: 0,
		agreements: [],
		currentAgreementPage: 1,
	}),
	watch: {
		'filters.date_from'() {
			this.listenFilters();
		},
		'filters.date_to'() {
			this.listenFilters();
		},
	},
	computed: {
		...mapState('contract', ['contracts', 'contractsPagination']),

		result_selected: {
			get() {
				return this.value || null;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
	created() {
		this.searchQueryParams();
	},
	methods: {
		...mapActions('contract', ['searchContract', 'cleanContracts', 'getContract']),
		...mapActions('document', ['getTemplate']),
		...mapActions('agreement', ['searchAgreement', 'cleanAgreements']),

		async selectResult(result) {
			if (result?.document_type_id && result.documentType.flag_contract_form) {

				const { ok, response } = await this.getContract(result?.id);

				let documents = [];
				if (Array.isArray(result?.documents) && result?.documents.length) {
					documents = result?.documents;
				} else {
					documents = response?.data?.documents || [];
				}

				if (ok) {
					
					const typeDocumentSearch = result.contractVersions && result.contractVersions.length > 0 ? false : true
					
					this.getTemplate(result?.document_type_id);
					this.result_selected = { ...response?.data, ...result, documents,...{fileActually:response?.data.file}, ...{ typeDocumentSearch: typeDocumentSearch } };
					// this.result_selected = { ...response?.data, documents };
				}
			} else {
			
				this.result_selected = result;
			}
		},

		setResult(result) {
			this.result_selected = result;
		},

		isSelected(result) {
			return result?.id == this.result_selected?.id ? 'selected' : '';
		},

		getIconLock(privacyLevel) {
			switch (privacyLevel) {
				case 'high':
					return 'mdi-lock';
				case 'medium':
					return 'mdi-lock-open';
				default:
					return '';
			}
		},

		listenFilters() {
			this.filters.page = 1;
			this.currentAgreementPage = 1;
			this.search();
		},

		listenSearch(value) {
			clearTimeout(this.timeout);
			if ((value || '').length > 0 || value == '') {
				this.timeout = setTimeout(async () => {
					this.filters.q = value;
					this.filters.page = 1;
					this.currentAgreementPage = 1;
					await this.search();
					clearTimeout(this.timeout);
				}, 800);
			}
		},

		async search() {
			if (this.filters?.q || this.filters?.document) {
				this.getAgreement();
				this.result_selected = null;
				this.isLoading = true;
				this.$emit('setFilters', this.filters);
				const { ok, error } = await this.searchContract(this.filters);
				if (!ok) {
					this.showAlert(error);
				}
				this.isLoading = false;
			} else {
				this.cleanContracts();
				this.result_selected = null;
			}
		},

		async getAgreement() {
			if (this.filters?.q || this.filters?.document) {
				const data = { ...this.filters, page: this.currentAgreementPage };
				const { ok, error } = await this.searchAgreement(data);
				if (!ok) {
					this.showAlert(error);
				}
			} else {
				this.cleanAgreements();
				//this.current_agreement = null;
			}
		},

		showAlert(error) {
			let msm = error?.data?.message;
			msm = Array.isArray(msm) ? msm.join(', ') : msm;
			$EventBus.$emit('showSnack', 'error', msm);
		},

		getColor(expr) {
			switch (expr) {
				case 'PROVIDER':
					return 'yellow accent-1';
				case 'CLIENT':
					return 'green accent-2';
				case 'Pre - Contrato':
					return 'red lighten-4';
				case 'Contrato':
					return 'purple lighten-4';
				case 'LETTER':
					return 'cyan lighten-4';
				case 'ADDENDUM':
					return 'light-blue lighten-4';
				case 'REPORT':
					return 'teal lighten-4';
				default:
					return 'grey';
			}
		},

		getValue(expr) {
			switch (expr) {
				case 'PROVIDER':
					return 'Proveedor';
				case 'CLIENT':
					return 'Cliente';
				default:
					return '-';
			}
		},

		getCountkeyword(item) {
			const pages = item?.contractPages;
			let count = Array.isArray(pages) ? pages.length : 0;
			if (Array.isArray(item.contractVersions)) {
				item.contractVersions.forEach((version) => {
					count += Array.isArray(version.contractVersionPages)
						? version.contractVersionPages.length
						: 0;
				});
			}
			if (Array.isArray(item.documents)) {
				item.documents.forEach((document) => {
					count += Array.isArray(document.contractDocumentPages)
						? document.contractDocumentPages.length
						: 0;
				});
			}

			if (count === 1) {
				return '1 resultado';
			} else if (count > 1) {
				return `${count} resultados`;
			} else {
				return null;
			}
		},

		hasKeyword(item) {
			const pages = item?.contractPages && item?.contractPages.length > 0 ? item?.contractPages : item?.contractVersions && item?.contractVersions[0]?.contractVersionPages;
			// const pages = item?.contractPages;
			let count = Array.isArray(pages) ? pages.length : 0;
			const documents = this.getTag(item);
			if (Array.isArray(documents) && documents.length > 0) {
				count += documents.length;
			}
			return count > 0;
		},

		listenMoreFilters(v) {
			if (!v) {
				this.filters.date_from = null;
				this.filters.date_to = null;
				this.filters.document_type = null;
			}
		},

		getLabel(expr) {
			const types = [
				{ value: 'LETTER', label: 'Carta' },
				{ value: 'ADDENDUM', label: 'Adenda ' },
				{ value: 'REPORT', label: 'Acta' },
			];
			const found = types.find((e) => e.value === expr);
			return found?.label || expr;
		},

		getTag(result) {
			let arr = [];
			const documents = Array.isArray(result?.documents) ? result?.documents : [];
			documents.forEach((document) => {
				if (
					Array.isArray(document?.contractDocumentPages) &&
					document?.contractDocumentPages.length > 0
				) {
					const result = arr.some((e) => e.documentType == document?.type);
					if (!result) {
						arr.push({
							label: this.getLabel(document?.type),
							color: this.getColor(document?.type),
							documentType: document?.type,
						});
					}
				}
			});
			return arr;
		},

		selectDocument(contract, document) {
			const { id, documents, file, owner, name, documentType } = contract;
			this.result_selected = {
				id,
				file,
				name,
				owner,
				documents,
				documentType,
				idDocument: document?.id,
				preview: 'DOCUMENT-KEYWORD',
			};
		},

		formatDate(date) {
			if (!isNaN(new Date(date))) {
				moment.locale('es');
				return date ? moment(date).format('DD-MM-YYYY') : null;
			} else {
				return null;
			}
		},

		getCountDocumentkeyword(document) {
			let count = 0;
			if (Array.isArray(document?.contractDocumentPages)) {
				count = document?.contractDocumentPages.length;
			}
			if (count === 1) {
				return '1 resultado';
			} else if (count > 1) {
				return `${count} resultados`;
			} else {
				return null;
			}
		},

		searchQueryParams() {
			this.$nextTick(function () {
				if (this.$route?.query?.owner && this.$refs?.inputOwner) {
					this.$refs.inputOwner.setOwner(this.$route?.query?.owner);
				}
			});
		},
	},
	destroyed() {
		this.cleanContracts();
		this.result_selected = null;
	},
	components: {
		CDatePicker,
		TagList,
		SearchDocument,
		AgreementSearchResults,
	},
};
